<template>
    <div>
        <v-btn
            @click="action == 'create' ? save() : edit()"
            :disabled="!validationResult"
            class="buttons buttons--add"
        >
            {{ $t('global:save') }}
        </v-btn>
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
export default {
    i18nOptions: { namespaces: ['global'] },
    props: {
        action: {
            type: String,
        },
    },
    computed: {
        ...mapGetters(['getValidationSteps']),
        validationResult: {
            get() {
                const validationResultStepOne =
                    this.getValidationSteps.mainRecordValidationStepOne || false
                const validationResultStepTwo =
                    this.getValidationSteps.mainRecordValidationStepTwo || false
                return validationResultStepOne && validationResultStepTwo
            },
        },
    },
    data() {
        return {
            disabled: false,
        }
    },

    methods: {
        ...mapActions(['createCRM', 'updateOffer']),
        async save() {
            if (this.validationResult) {
                this.disabled = true
                let success = await this.createCRM()
                if (success) {
                    this.$emit('closeModal')
                }
                this.$emit('closeModal')
                return
            } else {
                // console.log('Błąd walidacji')
            }
        },
        async edit() {
            // this.disabled = true
            // let success = await this.updateOffer({ id: this.$route.params.id })
            // if (success) this.$emit('closeModal')
            // this.disabled = false
        },
    },
}
</script>
