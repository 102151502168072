<template>
    <div class="modal-container">
        <v-form ref="reportForm">
            <div class="select-person mt-5 pl-5 pr-5">
                <v-select :rules="[validateIdRequired]" @change="
                    validateWorkerValue()
                " class="hidden-data" :items="possibleCRMParticipants" dense v-model="selectedUser"
                    :label="`Pracownik`" item-value="_id" return-object>
                    <template v-slot:selection="selection">
                        <span>{{ selection.item.name }}</span>
                        <span class="ml-1">{{ selection.item.lastname }}</span>
                    </template>
                    <template v-slot:item="item">
                        <span>{{ item.item.name }}</span>
                        <span class="ml-1">{{ item.item.lastname }}</span>
                    </template></v-select>
            </div>
            <div class="picker-container mt-5 pl-4 pr-4">
                <v-date-picker :first-day-of-week="1" class="date-picker-range" :landscape="true" :full-width="true" @change="validateWorkerValue"
                    v-model="datesRange" range flat elevation="5"></v-date-picker>

            </div>

            <div class="selected-date mt-2">

                <div class="date-error">{{ this.dateError }}</div>
            </div>
            <div class="create-report-container  mt-5">
                <v-btn :disabled="!validateValue" @click="createReport" class="report-btn" :color="'#445D75'">
                    <span color="text-btn">Wygeneruj raport</span>
                </v-btn>
            </div>
            <div class="single-line mt-3"></div>
            <div class="month-year-summary mt-5"><span>OFERTY &lpar;<v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <span class="text-toolip" v-on="on">{{ getOffersInCurrentMonthValue }}</span>
                        </template>
                        <span>Liczba ofert w aktualnym miesiącu</span>
                    </v-tooltip>/<v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <span class="text-toolip" v-on="on">{{ getOffersInYearValue }}</span>
                        </template>
                        <span>Liczba ofert w roku</span>
                    </v-tooltip>&rpar;</span></div>

            <div class="report-one">
                <div class="inner">
                    <span>WYGRANE</span><span class="winValue">{{ this.wins }}</span>
                </div>
                <div class="inner">
                    <span>PRZEGRANE</span><span class="lossValue">{{ this.loss }}</span>
                </div>
            </div>
            <div class="single-line mt-4"></div>
            <div class="month-year-summary mt-5"><span>ZDARZENIA &lpar;<v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <span class="text-toolip" v-on="on">{{ getAllEventsInMonth }}</span>
                        </template>
                        <span>Liczba zdarzeń w aktualnym miesiącu</span>
                    </v-tooltip>/<v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <span class="text-toolip" v-on="on">{{ getAllEventsInYear }}</span>
                        </template>
                        <span>Liczba zdarzeń w roku</span>
                    </v-tooltip>&rpar;</span>
            </div>
            <div class="report-two mb-5">
                <div class="inner">
                    <span>Telefony</span><span class="text-value">{{ this.phones }}</span>
                </div>
                <div class="inner">
                    <span>Emaile</span><span class="text-value">{{ this.emails }}</span>
                </div>
                <div class="inner">
                    <span>Spotkania</span><span class="text-value">{{ this.meets }}</span>
                </div>
            </div>
        </v-form>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import moment from 'moment'
export default {
    props: ['openAnalysisModalBoolean'],
    data() {
        return {
            datePickerBoolean: false,
            datesRange: ['', ''],
            possibleCRMParticipants: [],
            selectedUser: '',
            wins: 0,
            loss: 0,
            meets: 0,
            emails: 0,
            phones: 0,
            validateValue: false,
            dateError: '',
            offersInYear: 0,
            offersInCurrentMonth: 0,
            allEventsInYear: 0,
            allEventsInMonth: 0,
        }

    },
    computed: {
        ...mapGetters([]),
        getOffersInCurrentMonthValue() {
            return this.offersInCurrentMonth;
        },
        getOffersInYearValue() {
            return this.offersInYear;
        },
        getAllEventsInMonth() {
            return this.allEventsInMonth;
        },
        getAllEventsInYear() {
            return this.allEventsInYear;
        }
    },
    methods: {
        ...mapActions(['findUsersWithAccessToCRM', 'createReportForSelectedUserAndDate']),
        clearData() {
            this.datePickerBoolean = false
            this.datesRange = ['', '']
            this.selectedUser = ''
            this.wins = 0
            this.loss = 0
            this.meets = 0
            this.emails = 0
            this.phones = 0
            this.validateValue = false
            this.dateError = ''
            this.offersInYear = 0
            this.offersInCurrentMonth = 0
            this.allEventsInYear = 0
            this.allEventsInMonth = 0
        },
        validateIdRequired(item) {
            if (item?._id) {
                return true
            } else {
                return 'Wybierz pracownika'
            }
        },
        validateWorkerValue() {
            this.dateError = ''
            // CHECK IS START DATE IS BIGGER THAN END DATE
            if (moment(this.datesRange[0]).isSameOrBefore(moment(this.datesRange[1]))) {
                this.validateValue = true;
            } else {
                this.dateError = 'Wybrane daty znajdują się w nieprawidłowym przedziale'
                this.validateValue = false;
                return;
            }
            this.validateValue = this.$refs.reportForm.validate();
        },
        openRangeDatePickerFunc() {
            this.datePickerBoolean = !this.datePickerBoolean
        },

        async fetchPossibleCRMParticipants() {
            this.possibleCRMParticipants = await this.findUsersWithAccessToCRM()
        },
        async createReport() {
            const startDate = this.datesRange[0];
            const endDate = this.datesRange[1];
            const selectedUser = this.selectedUser;
            const response = await this.createReportForSelectedUserAndDate({
                startDate,
                endDate,
                selectedUser
            })
            if (response) {
                this.wins = response.wins;
                this.loss = response.loss;
                this.meets = response.meets;
                this.phones = response.phones;
                this.emails = response.emails;
                this.offersInYear = response.allEventsWithOfferBooleanTrue;
                this.offersInCurrentMonth = response.allEventsWithOfferBooleanTrueInCurrentMonth;
                this.allEventsInYear = response.allEventsForLastYearAndUser;
                this.allEventsInMonth = response.allEventsForCurrentMonthAndUser;
            }


        }
    },
    watch: {
        openAnalysisModalBoolean(oldValue, newValue) {
            if (oldValue !== newValue) {
                this.clearData();
            }
        }
    },
    async mounted() {
        await this.fetchPossibleCRMParticipants()
    },
}
</script>

<style lang="scss">
.modal-container {
    display: grid;
    grid-template-rows: 1fr;


    .text-toolip {
        cursor: pointer;
    }

    .single-line {
        border: 1px solid #8C8C8C;
        margin-left: 20px;
        margin-right: 20px;
    }

    .month-year-summary {
        text-align: center;
        font-weight: 500;
        font-size: 20px;
    }

    .selected-date {
        text-align: center;
        font-weight: bold;

        .date-error {
            text-align: center;
            color: #C23A3A;
        }
    }

    .create-report-container {
        text-align: center;

        .report-btn {
            color: white;
        }
    }



    .report-one {
        margin-top: 10px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        text-align: center;

        .inner {
            font-weight: bold;
            display: grid;
            grid-template-columns: 1fr;

            .winValue {
                color: #19AA8D;
            }

            .lossValue {
                color: #C23A3A
            }
        }
    }

    .report-two {
        margin-top: 10px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        text-align: center;

        .inner {
            font-weight: bold;
            display: grid;
            grid-template-columns: 1fr;


        }

        .text-value {
            color: #445D75;
        }
    }

}
</style>
