<template>
    <v-container class="pt-0">
        <v-row>
            <v-col cols="12" class="pa-0">
                <v-stepper v-model="getCRMStep.step" class="elevation-0">
                    <template>
                        <v-stepper-header class="elevation-0 stepperHeader">
                            <v-divider></v-divider>
                            <v-stepper-step
                                :rules="[() => getCRMErrors.stepOne.validation]"
                                editable
                                step="1"
                                >{{ $t('offers:basicInfo') }}</v-stepper-step
                            >
                            <v-divider></v-divider>
                            <v-stepper-step
                                :rules="[() => getCRMErrors.stepTwo.validation]"
                                editable
                                step="2"
                                >{{ 'Zdarzenie' }}</v-stepper-step
                            >
                            <v-divider></v-divider>
                        </v-stepper-header>
                        <v-stepper-items>
                            <v-stepper-content class="pb-0 pt-4" step="1">
                                <v-row>
                                    <v-col cols="12" md="12" class="pt-0">
                                        <Separator
                                            class="mb-0"
                                            :text="$t('offers:basicInfo')"
                                        />
                                    </v-col>
                                </v-row>
                                <v-form
                                    ref="mainRecordValidationStepOne"
                                    v-model="
                                        getValidationSteps.mainRecordValidationStepOne
                                    "
                                >
                                    <v-row>
                                        <v-col cols="6"
                                            ><v-text-field
                                                :rules="requiredRules"
                                                @change="
                                                    validateMainRecordValidationStepOne()
                                                "
                                                v-model="getCRMModal.title"
                                                :label="`${$t(
                                                    'offers:title'
                                                )}*`"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col>
                                            <v-combobox
                                                v-model="getCRMModal.contractor"
                                                :rules="requiredRules"
                                                :label="`${$t(
                                                    'offers:contractor'
                                                )}*`"
                                                hint="Zacznij pisać..."
                                                @keyup="
                                                    setContacts(
                                                        $event.target.value
                                                    )
                                                "
                                                @input="assignID()"
                                                :hide-no-data="hideContacts"
                                                :items="getContactsInput.items"
                                                item-text="name"
                                                item-value="_id"
                                                return-object
                                                :error-messages="
                                                    getCRMErrors.stepOne.fields
                                                        .contractor
                                                "
                                                @change="
                                                    ifClientOperationLocked()
                                                    validateMainRecordValidationStepOne()
                                                "
                                            >
                                            </v-combobox>
                                            <span class="contractorErrors"
                                                >{{ contractorErrors }}
                                            </span>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="6">
                                            <v-select
                                                :rules="requiredRules"
                                                @change="
                                                    validateMainRecordValidationStepOne()
                                                "
                                                v-model="getCRMModal.offerType"
                                                :items="
                                                    getRegistryDictionaries.crmOfferType
                                                        ? getRegistryDictionaries.crmOfferType
                                                        : []
                                                "
                                                :error-messages="
                                                    getCRMErrors.stepOne.fields
                                                        .offerType
                                                "
                                                :label="`${$t(
                                                    'offers:offerKind'
                                                )}*`"
                                                return-object
                                                item-value="field"
                                                ><template v-slot:item="data">
                                                    <span
                                                        v-if="
                                                            data.item
                                                                .backgroundColor
                                                        "
                                                    >
                                                        <v-chip
                                                            class="mx-2"
                                                            :color="
                                                                data.item
                                                                    .backgroundColor
                                                            "
                                                            small
                                                            label
                                                            :text-color="
                                                                data.item
                                                                    .textColor
                                                                    ? data.item
                                                                          .textColor
                                                                    : '#4d4d4d'
                                                            "
                                                        >
                                                            {{
                                                                data.item.field
                                                            }}
                                                        </v-chip>
                                                    </span>
                                                    <span
                                                        class="truncate-list"
                                                        v-else
                                                    >
                                                        {{ data.item.field }}
                                                    </span>
                                                </template>
                                                <template
                                                    v-slot:selection="data"
                                                >
                                                    <span
                                                        v-if="
                                                            data.item
                                                                .backgroundColor
                                                        "
                                                    >
                                                        <v-chip
                                                            class="mx-2"
                                                            :color="
                                                                data.item
                                                                    .backgroundColor
                                                            "
                                                            small
                                                            label
                                                            :text-color="
                                                                data.item
                                                                    .textColor
                                                                    ? data.item
                                                                          .textColor
                                                                    : '#4d4d4d'
                                                            "
                                                        >
                                                            {{
                                                                data.item.field
                                                            }}
                                                        </v-chip>
                                                    </span>
                                                    <span
                                                        class="truncate-list"
                                                        v-else
                                                    >
                                                        {{ data.item.field }}
                                                    </span>
                                                </template>
                                            </v-select>
                                        </v-col>
                                        <v-col cols="6">
                                            <v-select
                                                :rules="requiredRules"
                                                @change="
                                                    validateMainRecordValidationStepOne()
                                                "
                                                v-model="
                                                    getCRMModal.customerType
                                                "
                                                :error-messages="
                                                    getCRMErrors.stepOne.fields
                                                        .customerType
                                                "
                                                :items="
                                                    getRegistryDictionaries.crmCustomerType
                                                        ? getRegistryDictionaries.crmCustomerType
                                                        : []
                                                "
                                                :label="`${$t(
                                                    'offers:customerKind'
                                                )}*`"
                                                return-object
                                                item-value="field"
                                                ><template v-slot:item="data">
                                                    <span
                                                        v-if="
                                                            data.item
                                                                .backgroundColor
                                                        "
                                                    >
                                                        <v-chip
                                                            class="mx-2"
                                                            :color="
                                                                data.item
                                                                    .backgroundColor
                                                            "
                                                            small
                                                            label
                                                            :text-color="
                                                                data.item
                                                                    .textColor
                                                                    ? data.item
                                                                          .textColor
                                                                    : '#4d4d4d'
                                                            "
                                                        >
                                                            {{
                                                                data.item.field
                                                            }}
                                                        </v-chip>
                                                    </span>
                                                    <span
                                                        class="truncate-list"
                                                        v-else
                                                    >
                                                        {{ data.item.field }}
                                                    </span>
                                                </template>
                                                <template
                                                    v-slot:selection="data"
                                                >
                                                    <span
                                                        v-if="
                                                            data.item
                                                                .backgroundColor
                                                        "
                                                    >
                                                        <v-chip
                                                            class="mx-2"
                                                            :color="
                                                                data.item
                                                                    .backgroundColor
                                                            "
                                                            small
                                                            label
                                                            :text-color="
                                                                data.item
                                                                    .textColor
                                                                    ? data.item
                                                                          .textColor
                                                                    : '#4d4d4d'
                                                            "
                                                        >
                                                            {{
                                                                data.item.field
                                                            }}
                                                        </v-chip>
                                                    </span>
                                                    <span
                                                        class="truncate-list"
                                                        v-else
                                                    >
                                                        {{ data.item.field }}
                                                    </span>
                                                </template>
                                            </v-select>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="6">
                                            <v-select
                                                :rules="requiredRules"
                                                @change="
                                                    validateMainRecordValidationStepTwo()
                                                "
                                                v-model="getCRMModal.prognosis"
                                                :items="
                                                    getRegistryDictionaries.crmEventPrognosis
                                                        ? getRegistryDictionaries.crmEventPrognosis
                                                        : []
                                                "
                                                :label="'Rokowanie*'"
                                                return-object
                                                item-value="field"
                                                ><template v-slot:item="data">
                                                    <span
                                                        v-if="
                                                            data.item
                                                                .backgroundColor
                                                        "
                                                    >
                                                        <v-chip
                                                            class="mx-2"
                                                            :color="
                                                                data.item
                                                                    .backgroundColor
                                                            "
                                                            small
                                                            label
                                                            :text-color="
                                                                data.item
                                                                    .textColor
                                                                    ? data.item
                                                                          .textColor
                                                                    : '#4d4d4d'
                                                            "
                                                        >
                                                            {{
                                                                data.item.field
                                                            }}
                                                        </v-chip>
                                                    </span>
                                                    <span
                                                        class="truncate-list"
                                                        v-else
                                                    >
                                                        {{ data.item.field }}
                                                    </span>
                                                </template>
                                                <template
                                                    v-slot:selection="data"
                                                >
                                                    <span
                                                        v-if="
                                                            data.item
                                                                .backgroundColor
                                                        "
                                                    >
                                                        <v-chip
                                                            class="mx-2"
                                                            :color="
                                                                data.item
                                                                    .backgroundColor
                                                            "
                                                            small
                                                            label
                                                            :text-color="
                                                                data.item
                                                                    .textColor
                                                                    ? data.item
                                                                          .textColor
                                                                    : '#4d4d4d'
                                                            "
                                                        >
                                                            {{
                                                                data.item.field
                                                            }}
                                                        </v-chip>
                                                    </span>
                                                    <span
                                                        class="truncate-list"
                                                        v-else
                                                    >
                                                        {{ data.item.field }}
                                                    </span>
                                                </template>
                                            </v-select>
                                        </v-col></v-row
                                    >
                                    <v-row>
                                        <v-col offset="5" cols="6" sm="2">
                                            <v-checkbox
                                                class="checkbox--lead"
                                                v-model="
                                                    getCRMModal.leadBoolean
                                                "
                                                :label="'Lead'"
                                            ></v-checkbox>
                                        </v-col>
                                    </v-row>
                                </v-form>
                            </v-stepper-content>
                            <v-stepper-content class="pb-0 pt-4" step="2">
                                <v-form
                                    ref="mainRecordValidationStepTwo"
                                    v-model="
                                        getValidationSteps.mainRecordValidationStepTwo
                                    "
                                >
                                    <v-row>
                                        <v-col cols="12" md="12" class="pt-0">
                                            <Separator :text="'Zdarzenie'" />
                                        </v-col>
                                    </v-row>

                                    <v-row>
                                        <v-col cols="4">
                                            <v-select
                                                :rules="requiredRules"
                                                @change="
                                                    validateMainRecordValidationStepTwo()
                                                "
                                                v-model="
                                                    getCRMModal.crmEvent
                                                        .eventType
                                                "
                                                :error-messages="
                                                    getCRMErrors.stepTwo.fields
                                                        .eventType
                                                "
                                                :items="
                                                    getRegistryDictionaries.crmEventType
                                                        ? getRegistryDictionaries.crmEventType
                                                        : []
                                                "
                                                :label="'Zdarzenie*'"
                                                return-object
                                                item-value="field"
                                                ><template v-slot:item="data">
                                                    <span
                                                        v-if="
                                                            data.item
                                                                .backgroundColor
                                                        "
                                                    >
                                                        <v-chip
                                                            class="mx-2"
                                                            :color="
                                                                data.item
                                                                    .backgroundColor
                                                            "
                                                            small
                                                            label
                                                            :text-color="
                                                                data.item
                                                                    .textColor
                                                                    ? data.item
                                                                          .textColor
                                                                    : '#4d4d4d'
                                                            "
                                                        >
                                                            {{
                                                                data.item.field
                                                            }}
                                                        </v-chip>
                                                    </span>
                                                    <span
                                                        class="truncate-list"
                                                        v-else
                                                    >
                                                        {{ data.item.field }}
                                                    </span>
                                                </template>
                                                <template
                                                    v-slot:selection="data"
                                                >
                                                    <span
                                                        v-if="
                                                            data.item
                                                                .backgroundColor
                                                        "
                                                    >
                                                        <v-chip
                                                            class="mx-2"
                                                            :color="
                                                                data.item
                                                                    .backgroundColor
                                                            "
                                                            small
                                                            label
                                                            :text-color="
                                                                data.item
                                                                    .textColor
                                                                    ? data.item
                                                                          .textColor
                                                                    : '#4d4d4d'
                                                            "
                                                        >
                                                            {{
                                                                data.item.field
                                                            }}
                                                        </v-chip>
                                                    </span>
                                                    <span
                                                        class="truncate-list"
                                                        v-else
                                                    >
                                                        {{ data.item.field }}
                                                    </span>
                                                </template>
                                            </v-select>
                                        </v-col>
                                        <v-col cols="8">
                                            <v-select
                                                :rules="requiredRules"
                                                @change="
                                                    validateMainRecordValidationStepTwo()
                                                "
                                                :label="`${$t(
                                                    'offers:representative'
                                                )}*`"
                                                v-model="
                                                    getCRMModal.crmEvent
                                                        .representative
                                                "
                                                :error-messages="
                                                    getCRMErrors.stepTwo.fields
                                                        .representative
                                                "
                                                :item-text="text"
                                                return-object
                                                append-outer-icon="mdi-account-plus"
                                                item-value="_id"
                                                :items="
                                                    getRepresentativeInput.items
                                                "
                                                ><template
                                                    slot="selection"
                                                    slot-scope="data"
                                                >
                                                    {{ data.item.name }}
                                                    {{ data.item.lastname }}
                                                </template>
                                                <template
                                                    slot="item"
                                                    slot-scope="data"
                                                >
                                                    {{ data.item.name }}
                                                    {{ data.item.lastname }}
                                                </template>
                                                <template slot="append-outer">
                                                    <v-menu
                                                        :close-on-content-click="
                                                            false
                                                        "
                                                        :close-on-click="false"
                                                        @input="
                                                            v =>
                                                                v ||
                                                                clearMiniModal()
                                                        "
                                                        max-width="300"
                                                        min-width="300"
                                                        origin="center"
                                                        v-model="showMenu"
                                                    >
                                                        <template
                                                            v-slot:activator="{
                                                                on: menu,
                                                            }"
                                                        >
                                                            <v-tooltip top>
                                                                <template
                                                                    v-slot:activator="{
                                                                        on: tooltip,
                                                                    }"
                                                                >
                                                                    <v-btn
                                                                        small
                                                                        icon
                                                                        :disabled="
                                                                            getContactsInput
                                                                                .items
                                                                                .length >
                                                                            0
                                                                                ? false
                                                                                : true
                                                                        "
                                                                    >
                                                                        <v-icon
                                                                            v-on="{
                                                                                ...menu,
                                                                                ...tooltip,
                                                                            }"
                                                                            @click="
                                                                                showMenu = true
                                                                            "
                                                                            >mdi-plus</v-icon
                                                                        >
                                                                    </v-btn>
                                                                </template>
                                                                <span>{{
                                                                    $t(
                                                                        'offers:addRepresentative'
                                                                    )
                                                                }}</span>
                                                            </v-tooltip>
                                                        </template>
                                                        <v-card>
                                                            <v-container>
                                                                <v-row>
                                                                    <v-col
                                                                        cols="12"
                                                                    >
                                                                        <h3>
                                                                            {{
                                                                                `${$t(
                                                                                    'offers:additionRepresentative'
                                                                                )}`
                                                                            }}
                                                                        </h3>
                                                                    </v-col>
                                                                </v-row>
                                                                <v-row>
                                                                    <v-col
                                                                        cols="12"
                                                                    >
                                                                        <v-text-field
                                                                            v-model="
                                                                                getRepresentativeModal.name
                                                                            "
                                                                            :label="`${$t(
                                                                                'offers:representativeName'
                                                                            )}`"
                                                                        >
                                                                        </v-text-field>
                                                                    </v-col>
                                                                </v-row>
                                                                <v-row>
                                                                    <v-col
                                                                        cols="12"
                                                                    >
                                                                        <v-text-field
                                                                            v-model="
                                                                                getRepresentativeModal.lastname
                                                                            "
                                                                            :label="`${$t(
                                                                                'offers:representativeLastname'
                                                                            )}`"
                                                                        >
                                                                        </v-text-field>
                                                                    </v-col>
                                                                </v-row>
                                                                <v-row>
                                                                    <v-col
                                                                        cols="12"
                                                                    >
                                                                        <v-text-field
                                                                            v-model="
                                                                                getRepresentativeModal.position
                                                                            "
                                                                            :label="`${$t(
                                                                                'Stanowisko'
                                                                            )}`"
                                                                        >
                                                                        </v-text-field>
                                                                    </v-col>
                                                                </v-row>
                                                                <v-row>
                                                                    <v-col
                                                                        cols="12"
                                                                    >
                                                                        <v-text-field
                                                                            v-model="
                                                                                getRepresentativeModal.email
                                                                            "
                                                                            :label="`${$t(
                                                                                'offers:representativeEmail'
                                                                            )}`"
                                                                        >
                                                                        </v-text-field>
                                                                    </v-col>
                                                                </v-row>

                                                                <v-row>
                                                                    <v-col
                                                                        cols="12"
                                                                    >
                                                                        <v-text-field
                                                                            v-model="
                                                                                getRepresentativeModal.phoneNumber
                                                                            "
                                                                            :label="`${$t(
                                                                                'offers:representativePhoneNumber'
                                                                            )}`"
                                                                        >
                                                                        </v-text-field>
                                                                    </v-col>
                                                                </v-row>
                                                                <v-row>
                                                                    <v-col
                                                                        md="12"
                                                                        class="py-0 my-0 d-flex justify-end"
                                                                    >
                                                                        <v-btn
                                                                            class="buttons buttons--cancel mr-2"
                                                                            @click="
                                                                                clearMiniModal()
                                                                            "
                                                                            >{{
                                                                                $t(
                                                                                    'cancel'
                                                                                )
                                                                            }}</v-btn
                                                                        >
                                                                        <v-btn
                                                                            class="buttons buttons--add"
                                                                            :disabled="
                                                                                disabled
                                                                            "
                                                                            @click="
                                                                                additionRepresentative()
                                                                            "
                                                                            >{{
                                                                                $t(
                                                                                    'add'
                                                                                )
                                                                            }}</v-btn
                                                                        >
                                                                    </v-col>
                                                                </v-row>
                                                            </v-container>
                                                        </v-card>
                                                    </v-menu>
                                                </template>
                                            </v-select>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col offset="4" cols="5">
                                            <v-select
                                                :rules="requiredRules"
                                                @change="
                                                    validateMainRecordValidationStepTwo()
                                                "
                                                :label="`Decyzyjność reprezentanta*`"
                                                v-model="
                                                    getCRMModal.crmEvent
                                                        .representativeDecisionType
                                                "
                                                :error-messages="
                                                    getCRMErrors.stepTwo.fields
                                                        .representativeDecisionType
                                                "
                                                return-object
                                                :items="[
                                                    'Decyzyjny',
                                                    'Niedecyzyjny',
                                                    'Wpływ na decyzję',
                                                    'Brak informacji',
                                                ]"
                                            >
                                            </v-select>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12"
                                            ><v-textarea
                                                :rules="requiredRules"
                                                @change="
                                                    validateMainRecordValidationStepTwo()
                                                "
                                                rows="1"
                                                auto-grow
                                                no-resize
                                                v-model="
                                                    getCRMModal.crmEvent
                                                        .description
                                                "
                                                :error-messages="
                                                    getCRMErrors.stepTwo.fields
                                                        .description
                                                "
                                                :label="`Opis zdarzenia*`"
                                            ></v-textarea>
                                        </v-col>

                                        <v-col offset="6" cols="4">
                                            <v-menu
                                                v-model="expectationsDate"
                                                :close-on-content-click="false"
                                                :nudge-right="40"
                                                transition="scale-transition"
                                                offset-y
                                                min-width="auto"
                                            >
                                                <template
                                                    v-slot:activator="{
                                                        on,
                                                        attrs,
                                                    }"
                                                >
                                                    <v-text-field
                                                        v-model="
                                                            getCRMModal.crmEvent
                                                                .nextEventDate
                                                        "
                                                        clearable
                                                        :label="`Kolejne zdarzenie - data`"
                                                        prepend-icon="mdi-calendar"
                                                        readonly
                                                        v-bind="attrs"
                                                        v-on="on"
                                                    >
                                                    </v-text-field>
                                                </template>
                                                <v-date-picker
                                                    first-day-of-week="1"
                                                    v-model="
                                                        getCRMModal.crmEvent
                                                            .nextEventDate
                                                    "
                                                    @input="
                                                        expectationsDate = false
                                                    "
                                                    :min="
                                                        $moment().format(
                                                            'YYYY-MM-DD'
                                                        )
                                                    "
                                                ></v-date-picker> </v-menu
                                        ></v-col>
                                        <v-col cols="2">
                                            <v-menu
                                                ref="menu2"
                                                v-model="expectedTime"
                                                :close-on-content-click="false"
                                                :nudge-right="40"
                                                :return-value.sync="
                                                    getCRMModal.crmEvent
                                                        .nextEventTime
                                                "
                                                transition="scale-transition"
                                                offset-y
                                                max-width="290px"
                                                min-width="290px"
                                            >
                                                <template
                                                    v-slot:activator="{
                                                        on,
                                                        attrs,
                                                    }"
                                                >
                                                    <v-text-field
                                                        v-model="
                                                            getCRMModal.crmEvent
                                                                .nextEventTime
                                                        "
                                                        clearable
                                                        :label="`${$t(
                                                            'service:time'
                                                        )}`"
                                                        prepend-icon="mdi-clock-time-four-outline"
                                                        readonly
                                                        v-bind="attrs"
                                                        v-on="on"
                                                    ></v-text-field>
                                                </template>
                                                <v-time-picker
                                                    v-if="expectedTime"
                                                    format="24hr"
                                                    v-model="
                                                        getCRMModal.crmEvent
                                                            .nextEventTime
                                                    "
                                                    :min="
                                                        getCRMModal.crmEvent
                                                            .nextEventDate ==
                                                        $moment().format(
                                                            'YYYY-MM-DD'
                                                        )
                                                            ? $moment().format(
                                                                  `HH:mm`
                                                              )
                                                            : null
                                                    "
                                                    full-width
                                                    @click:minute="
                                                        $refs.menu2.save(
                                                            getCRMModal.crmEvent
                                                                .nextEventTime
                                                        )
                                                    "
                                                ></v-time-picker>
                                            </v-menu>
                                        </v-col>
                                    </v-row>
                                    <v-row
                                        ><v-col cols="2">
                                            <v-checkbox
                                                class="checkbox--lead"
                                                v-model="
                                                    getCRMModal.crmEvent
                                                        .offerBoolean
                                                "
                                                :label="'Oferta'"
                                            ></v-checkbox>
                                        </v-col>
                                        <v-col offset="1" cols="4">
                                            <v-select
                                                :disabled="
                                                    !getCRMModal.crmEvent
                                                        .offerBoolean
                                                "
                                                :label="`Status oferty`"
                                                v-model="
                                                    getCRMModal.crmEvent
                                                        .offerStatus
                                                "
                                                return-object
                                                :items="[
                                                    'Wygrana',
                                                    'Przegrana',
                                                    'Odrzucona',
                                                    'Anulowana',
                                                ]"
                                            >
                                            </v-select>
                                        </v-col>
                                        <v-col offset="1" cols="4">
                                            <v-select
                                                v-model="
                                                    getCRMModal.crmEvent
                                                        .nextEventType
                                                "
                                                :items="
                                                    getRegistryDictionaries.crmEventType
                                                        ? getRegistryDictionaries.crmEventType
                                                        : []
                                                "
                                                :label="'Kolejne zdarzenie - rodzaj'"
                                                return-object
                                                item-value="field"
                                                ><template v-slot:item="data">
                                                    <span
                                                        v-if="
                                                            data.item
                                                                .backgroundColor
                                                        "
                                                    >
                                                        <v-chip
                                                            class="mx-2"
                                                            :color="
                                                                data.item
                                                                    .backgroundColor
                                                            "
                                                            small
                                                            label
                                                            :text-color="
                                                                data.item
                                                                    .textColor
                                                                    ? data.item
                                                                          .textColor
                                                                    : '#4d4d4d'
                                                            "
                                                        >
                                                            {{
                                                                data.item.field
                                                            }}
                                                        </v-chip>
                                                    </span>
                                                    <span
                                                        class="truncate-list"
                                                        v-else
                                                    >
                                                        {{ data.item.field }}
                                                    </span>
                                                </template>
                                                <template
                                                    v-slot:selection="data"
                                                >
                                                    <span
                                                        v-if="
                                                            data.item
                                                                .backgroundColor
                                                        "
                                                    >
                                                        <v-chip
                                                            class="mx-2"
                                                            :color="
                                                                data.item
                                                                    .backgroundColor
                                                            "
                                                            small
                                                            label
                                                            :text-color="
                                                                data.item
                                                                    .textColor
                                                                    ? data.item
                                                                          .textColor
                                                                    : '#4d4d4d'
                                                            "
                                                        >
                                                            {{
                                                                data.item.field
                                                            }}
                                                        </v-chip>
                                                    </span>
                                                    <span
                                                        class="truncate-list"
                                                        v-else
                                                    >
                                                        {{ data.item.field }}
                                                    </span>
                                                </template>
                                            </v-select>
                                        </v-col>
                                    </v-row>
                                </v-form>
                            </v-stepper-content>
                        </v-stepper-items>
                    </template>
                </v-stepper>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
    i18nOptions: { namespaces: ['global'] },
    computed: {
        ...mapGetters([
            'getCRMModal',
            'getCRMErrors',
            'getRegistryDictionaries',
            'getContactsInput',
            'getRepresentativeInput',
            'getRepresentativeModal',
            'getCRMStep',
            'getValidationSteps',
        ]),
    },
    data() {
        return {
            hideContacts: true,
            disabled: false,
            showMenu: false,
            step: 1,
            editable: true,
            contractorErrors: '',
            expectationsDate: '',
            expectedTime: '',
            requiredRules: [v => !!v || 'Pole jest wymagane'],
        }
    },
    methods: {
        ...mapActions([
            'fetchContactsInputs',
            'createRepresentative',
            'fetchRepresentativesInput',
            'checkIsClientOperationLocked',
            'setCRMValidation',
        ]),
        resetValidation() {
            this.$refs.mainRecordValidationStepOne.resetValidation()
            this.$refs.mainRecordValidationStepTwo.resetValidation()
        },
        ...mapMutations(['setContactsInput', 'setRepresentativesInput']),
        validateMainRecordValidationStepOne() {
            this.setCRMValidation({
                validationName: 'mainRecordValidationStepOne',
                value: this.$refs.mainRecordValidationStepOne.validate(),
            })
        },
        validateMainRecordValidationStepTwo() {
            this.setCRMValidation({
                validationName: 'mainRecordValidationStepTwo',
                value: this.$refs.mainRecordValidationStepTwo.validate(),
            })
        },
        text: item => item.name + item.lastname,
        setContacts(search) {
            if (search.length >= 4) {
                this.setContactsInput({ search })
                this.fetchContactsInputs()
                this.hideContacts = false
            } else {
                this.getContactsInput.items = []
                this.hideContacts = true
            }
        },
        setRepresentatives(search) {
            this.setRepresentativesInput({ search })
            this.fetchRepresentativesInput({
                id: this.getLeasingModal.contact._id,
            })
        },
        assignID() {
            if (
                this.getCRMModal.contractor &&
                this.getCRMModal.contractor._id
            ) {
                this.getCRMModal.representative = null
                this.fetchRepresentativesInput({
                    id: this.getCRMModal.contractor._id,
                })
            }
        },
        async additionRepresentative() {
            this.disabled = true
            let success = await this.createRepresentative({
                id: this.getCRMModal.contractor._id,
            })
            if (success) {
                this.clearMiniModal()
                this.assignID()
            }

            this.disabled = false
        },
        clearMiniModal() {
            this.showMenu = false
        },
        async ifClientOperationLocked() {
            const clientId = this.$get(this.getCRMModal, 'contractor', '')?._id

            if (clientId) {
                const payload = {
                    id: clientId,
                }
                const checkClientOperationLocked = await this.checkIsClientOperationLocked(
                    payload
                )

                if (checkClientOperationLocked.data) {
                    // this.getCRMErrors.stepOne.fields.contractor =
                    //     'Klient na liście zablokowanych'
                    this.contractorErrors = 'Klient na liście zablokowanych'
                    // this.$forceUpdate()
                } else {
                    this.contractorErrors = ''
                    // this.getCRMErrors.stepOne.fields.contractor = ''

                    // this.$forceUpdate()
                }
            }
        },
    },
    async updated() {
        this.resetValidation()
    },
}
</script>
<style lang="sass" scoped>
.checkbox--lead
    font-weight: bold
.contractorErrors
    color: red
    font-size: 13px !important
</style>
