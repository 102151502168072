<template>
    <div class="modal-container">
        <v-form ref="reportForm">
            <div class="select-person mt-5 pl-5 pr-5">
                <v-select :rules="[validateIdRequired]" @change="
                    validateWorkerValue()
                " class="hidden-data" :items="possibleCRMParticipants" dense v-model="selectedUser"
                    :label="`Pracownik`" item-value="_id" return-object>
                    <template v-slot:selection="selection">
                        <span>{{ selection.item.name }}</span>
                        <span class="ml-1">{{ selection.item.lastname }}</span>
                    </template>
                    <template v-slot:item="item">
                        <span>{{ item.item.name }}</span>
                        <span class="ml-1">{{ item.item.lastname }}</span>
                    </template></v-select>
            </div>
            <div class="picker-container mt-5 pl-4 pr-4">
                <v-date-picker :first-day-of-week="1" class="date-picker-range" :landscape="true" :full-width="true" @change="validateWorkerValue"
                    v-model="datesRange" range flat elevation="5"></v-date-picker>

            </div>

            <div class="selected-date mt-2">

                <div class="date-error">{{ this.dateError }}</div>
            </div>
            <div class="create-report-container  mt-5">
                <v-btn :disabled="!validateValue" @click="createReport" class="report-btn" :color="'#445D75'">
                    <span color="text-btn">Wygeneruj raport</span>
                </v-btn>
            </div>

        </v-form>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import XLSX from 'sheetjs-style'
import moment from 'moment'
export default {
    props: ['openReportModalBoolean'],
    data() {
        return {
            datePickerBoolean: false,
            datesRange: ['', ''],
            possibleCRMParticipants: [],
            selectedUser: '',
            wins: 0,
            loss: 0,
            meets: 0,
            emails: 0,
            phones: 0,
            validateValue: false,
            dateError: '',
            offersInYear: 0,
            offersInCurrentMonth: 0,
            allEventsInYear: 0,
            allEventsInMonth: 0,
        }

    },
    computed: {
        ...mapGetters([]),
        getOffersInCurrentMonthValue() {
            return this.offersInCurrentMonth;
        },
        getOffersInYearValue() {
            return this.offersInYear;
        },
        getAllEventsInMonth() {
            return this.allEventsInMonth;
        },
        getAllEventsInYear() {
            return this.allEventsInYear;
        }
    },
    methods: {
        ...mapActions(['findUsersWithAccessToCRM', 'createReportForLockedRecord']),
        validateIdRequired(item) {
            if (item?._id) {
                return true
            } else {
                return 'Wybierz pracownika'
            }
        },
        validateWorkerValue() {
            this.dateError = ''
            // CHECK IS START DATE IS BIGGER THAN END DATE
            if (moment(this.datesRange[0]).isSameOrBefore(moment(this.datesRange[1]))) {
                this.validateValue = true;
            } else {
                this.dateError = 'Wybrane daty znajdują się w nieprawidłowym przedziale'
                this.validateValue = false;
                return;
            }
            this.validateValue = this.$refs.reportForm.validate();
        },
        openRangeDatePickerFunc() {
            this.datePickerBoolean = !this.datePickerBoolean
        },

        async fetchPossibleCRMParticipants() {
            this.possibleCRMParticipants = await this.findUsersWithAccessToCRM()
        },

        clearData() {
            this.datePickerBoolean = false,
                this.datesRange = ['', '']
            this.selectedUser = ''
            this.wins = 0
            this.loss = 0
            this.meets = 0
            this.emails = 0
            this.phones = 0
            this.validateValue = false
            this.dateError = ''
            this.offersInYear = 0
            this.offersInCurrentMonth = 0
            this.allEventsInYear = 0
            this.allEventsInMonth = 0
        },

        async createReport() {

            Object.flatten = function (data) {
                var result = {};
                function recurse(cur, prop) {
                    if (Object(cur) !== cur) {
                        result[prop] = cur;
                    } else if (Array.isArray(cur)) {
                        for (var i = 0, l = cur.length; i < l; i++)
                            recurse(cur[i], prop + "[" + i + "]");
                        if (l == 0)
                            result[prop] = [];
                    } else {
                        var isEmpty = true;
                        for (var p in cur) {
                            isEmpty = false;
                            recurse(cur[p], prop ? prop + "." + p : p);
                        }
                        if (isEmpty && prop)
                            result[prop] = {};
                    }
                }
                recurse(data, "");
                return result;
            }

            const startDate = this.datesRange[0];
            const endDate = this.datesRange[1];
            const selectedUser = this.selectedUser;


            let response = await this.createReportForLockedRecord({
                startDate,
                endDate,
                selectedUser
            })


            let formatedResponse = [];
            // FLAT OBJECTS IN ARRAY
            for (let object of response) {
                let value = Object.flatten(object);
                formatedResponse.push(value);
            }

            // ADD LP VALUE PER OBJECT
            for (let [index, value] of formatedResponse.entries()) {
                value.lp = index + 1;
            }

            // SET HEADERS FOR FILE
            let headers = [['Lp. ', 'Klient', 'Pochodzenie klienta', 'Model urządzenia', 'Stan urządzenia', 'Rodzaj', 'FTTR', 'Nr umowy / FV', 'Czas trwania umowy', 'Aneks', 'Aneksuje umowę', 'Prowizja', 'Uwagi']];
            // SELECT FIELDS TO EXTRACT FROM JSON
            const fields = ['lp', 'clientInfo.name', 'customerType.field', 'model.model', 'deviceState.field', 'subscriptionType.field', 'fttrValue', 'contractNumber', 'durationOfTheContract.field', 'isAnnex', 'blankingContract', , '', 'comments']
            let data = this.formatToSelectedFieldOnly(fields, formatedResponse)



            let row1 = [

                { v: "Pracownik", t: "s", s: { fill: { fgColor: { rgb: "FFEB9C" } } } },
                { v: `${selectedUser.name} ${selectedUser.lastname}`, t: "s", s: { fill: { fgColor: { rgb: "C6EFCE" } } } },
            ];
            let row2 = [

                { v: "Weryfikowany zakres", t: "s", s: { fill: { fgColor: { rgb: "FFEB9C" } } } },
                { v: `${startDate} - ${endDate}`, t: "s", s: { fill: { fgColor: { rgb: "C6EFCE" } } } },
            ];

            const wb = XLSX.utils.book_new();
            const ws = XLSX.utils.json_to_sheet([]);

            let wscols = [
                { wch: 30 },
                { wch: 30 },
                { wch: 20 },
                { wch: 30 },
                { wch: 20 },
                { wch: 20 },
                { wch: 15 },
                { wch: 20 },
                { wch: 20 },
                { wch: 15 },
                { wch: 20 },
                { wch: 20 },
                { wch: 20 },
                { wch: 30 },
                { wch: 30 },
            ];

            ws['!cols'] = wscols;

            XLSX.utils.sheet_add_aoa(ws, [row1, row2], { origin: 'A3', skipHeader: true });
            XLSX.utils.sheet_add_aoa(ws, headers, { origin: 'A7', skipHeader: true });
            XLSX.utils.sheet_add_json(ws, data, { origin: 'A8', skipHeader: true });

            this.setColorsOnRow(ws, ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M'], 7, 'C6EFCE')
            // ws['!protect'] = {};

            // ws['!protect'] =
            // {
            //     password: 'C@1235#'

            // }
            XLSX.utils.book_append_sheet(wb, ws, `Raport`);

            XLSX.writeFile(wb, `Raport dla ${selectedUser.name} ${selectedUser.lastname} - od ${startDate} do ${endDate}.xlsx`);

        },
        setColorsOnRow(ws, columnLetters, rowNumber, color) {

            for (let col of columnLetters) {
                ws[`${col}${rowNumber}`].s = {
                    fill: {
                        fgColor: { rgb: color }
                    }
                }
            }

        },
        formatToSelectedFieldOnly(FilterData, JsonData) {
            return JsonData.map((v) =>
                FilterData.map((j) => {
                    return v[j];
                })
            );
        }
    },
    watch: {
        openReportModalBoolean(oldValue, newValue) {
            if (oldValue !== newValue) {
                this.clearData();
            }
        }
    },
    async mounted() {
        await this.fetchPossibleCRMParticipants()
    },
}
</script>

<style lang="scss">
.modal-container {
    display: grid;
    grid-template-rows: 1fr;


    .text-toolip {
        cursor: pointer;
    }

    .single-line {
        border: 1px solid #8C8C8C;
        margin-left: 20px;
        margin-right: 20px;
    }

    .month-year-summary {
        text-align: center;
        font-weight: 500;
        font-size: 20px;
    }

    .selected-date {
        text-align: center;
        font-weight: bold;

        .date-error {
            text-align: center;
            color: #C23A3A;
        }
    }

    .create-report-container {
        text-align: center;

        .report-btn {
            color: white;
        }
    }



    .report-one {
        margin-top: 10px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        text-align: center;

        .inner {
            font-weight: bold;
            display: grid;
            grid-template-columns: 1fr;

            .winValue {
                color: #19AA8D;
            }

            .lossValue {
                color: #C23A3A
            }
        }
    }

    .report-two {
        margin-top: 10px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        text-align: center;

        .inner {
            font-weight: bold;
            display: grid;
            grid-template-columns: 1fr;


        }

        .text-value {
            color: #445D75;
        }
    }

}
</style>
