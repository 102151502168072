<template>
    <v-container class="p-relative">
        <v-row>
            <v-col cols="12" md="12" class="py-0">
                <RegistryHeader
                    :title="getCRMRegistry.name"
                    :avatarData="getCRMRegistry.currentMenager"
                    @clearSearch="clearTable"
                />
                <Table
                    ref="crmTable"
                    :title="getCRMRegistry.name"
                    :items="getCRMTable.items"
                    :headers="headers"
                    :length="getCRMTable.pages"
                    :page="getCRMTable.page"
                    @search="setSearch"
                    @clear-table="clearTable"
                    @search-fields="setSearchFields"
                    @change-page="setPage"
                    @sort="setSort"
                    :defaultFields="getCRMSearchFields.searchFields"
                    :loading="getCRMTable.loading"
                >
                    <Columns slot="columns" :items="getCRMTable.items" />
                </Table>
                <Modal
                    :title="'Dodawanie'"
                    :open="open"
                    :height="'640'"
                    v-on:close="closeModal"
                >
                    <AEContent slot="AEContent" />
                    <Buttons
                        :action="'create'"
                        slot="buttons"
                        @closeModal="open = false"
                    />
                </Modal>
                <div class="buttons--crm__control d-flex buttons--action">
                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                data-cy="addEntry"
                                fab
                                dark
                                v-on="on"
                                small
                                @click="sortTableByEventDateToday()"
                                class="buttons--add margin--1"
                            >
                                <v-icon color="white"
                                    >mdi-calendar-today</v-icon
                                >
                            </v-btn>
                        </template>
                        <span>Zdarzenia na dziś</span>
                    </v-tooltip>

                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                data-cy="addEntry"
                                fab
                                dark
                                v-on="on"
                                :color="'#445D75'"
                                small
                                @click="openRangeDatePickerFunc()"
                                class="margin--1 ml-3"
                            >
                                <v-icon color="white"
                                    >mdi-calendar-filter</v-icon
                                >
                            </v-btn>
                        </template>
                        <span>Wybór zdarzeń w czasie</span>
                    </v-tooltip>
                </div>

                <div class="d-flex justify-end buttons--action">
                    <v-tooltip left>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                data-cy="addEntry"
                                fab
                                dark
                                v-on="on"
                                small
                                :color="'#009999'"
                                @click="openReportModalFunc()"
                                class="margin--1"
                            >
                                <v-icon small>mdi-chart-areaspline</v-icon>
                            </v-btn>
                        </template>
                        <span>Raport do pliku .xlsx</span>
                    </v-tooltip>
                    <v-tooltip left>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                data-cy="addEntry"
                                fab
                                dark
                                v-on="on"
                                small
                                :color="'#2e4c46'"
                                @click="openAnalysisModalFunc()"
                                class="margin--1 ml-5"
                            >
                                <v-icon small>mdi-chart-bar</v-icon>
                            </v-btn>
                        </template>
                        <span>Raport okresowy</span>
                    </v-tooltip>
                    <v-tooltip left>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                data-cy="addEntry"
                                fab
                                dark
                                v-on="on"
                                small
                                v-if="add()"
                                @click="addDataToCRM()"
                                class="buttons--add margin--1 ml-5"
                            >
                                <v-icon small>mdi-plus</v-icon>
                            </v-btn>
                        </template>
                        <span>Utwórz</span>
                    </v-tooltip>
                </div>
            </v-col>
        </v-row>
        <v-date-picker
            class="date-picker-range"
            :first-day-of-week="1"
            v-if="openRangeDatePicker"
            v-model="datesRange"
            @change="sortTableByEventDateRange"
            range
        ></v-date-picker>
        <ModalToCRMAnalysis
            :first-day-of-week="1"
            :title="'Raport okresowy'"
            :open="openAnalysisModalBoolean"
            :height="'900'"
            v-on:close="closeAnalysisModalFunc()"
        >
            <AnalysisModelContent
                :openAnalysisModalBoolean="openAnalysisModalBoolean"
                slot="AEContent"
            />
            <!-- <Buttons
                @closeModal="open = false"
                :action="'edit'"
                :type="'INFORMATION'"
                slot="buttons"
            /> -->
        </ModalToCRMAnalysis>
        <ModalToCRMAnalysis
            :title="'Raportowanie'"
            :open="openReportModalBoolean"
            :height="'470'"
            v-on:close="closeReportModalFunc()"
        >
            <ReportModalContent
                :openReportModalBoolean="openReportModalBoolean"
                slot="AEContent"
            />
            <!-- <Buttons
                @closeModal="open = false"
                :action="'edit'"
                :type="'INFORMATION'"
                slot="buttons"
            /> -->
        </ModalToCRMAnalysis>
    </v-container>
</template>
<script>
import moment from 'moment'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import store from './../../../store/index'
import Columns from '../../../components/Registries/CRM/Table/Columns'
import AEContent from './../../../components/Registries/CRM/Modal/Content'
import Buttons from './../../../components/Registries/CRM/Modal/Buttons'
import AnalysisModelContent from '../../../components/Registries/CRM/Modal/AnalysisModalContent.vue'
import ReportModalContent from '../../../components/Registries/CRM/Modal/ReportModalContent.vue'
export default {
    data() {
        return {
            openRangeDatePicker: false,
            datesRange: [],
            open: false,
            headers: [
                {
                    text: this.$t('offers:index'),
                    value: 'index',
                    width: '12%',
                    showSearch: true,
                },
                {
                    text: this.$t('offers:contractor'),
                    value: 'contractor.name',
                    width: '35%',
                    showSearch: true,
                },
                {
                    text: 'Tytuł / Przedmiot procesu',
                    value: 'title',
                    width: '20%',
                    showSearch: true,
                },
                {
                    text: this.$t('offers:creator'),
                    value: 'createdBy.name join createdBy.lastname',
                    width: '15%',
                    showSearch: true,
                },
                {
                    text: this.$t('offers:creationDate'),
                    value: 'createdAt',
                    width: '10%',
                },
                {
                    text: '',
                    value: 'form',
                    width: '5%',
                    sortable: false,
                },
                {
                    text: '',
                    value: 'recordContainsAtLeastOneOffer',
                    width: 'auto',
                    showSearch: false,
                    sortable: false,
                },
                {
                    text: this.$t('offers:status'),
                    value: 'status.name',
                    width: '100px',
                    showSearch: true,
                },
                {
                    text: '',
                    value: 'arrow',
                    align: 'right',
                    width: '150px',
                    sortable: false,
                },
            ],
            openAnalysisModalBoolean: false,
            openReportModalBoolean: false,
        }
    },
    components: {
        Columns,
        AEContent,
        Buttons,
        AnalysisModelContent,
        ReportModalContent,
    },
    computed: {
        ...mapGetters([
            'getCRMRegistry',
            'getCRMTable',
            'getCRMSearchFields',
            'getSearch',
        ]),
    },
    methods: {
        ...mapActions([
            'fetchCRMTable',
            'fetchUsers',
            'findAllEventsBySelectedDate',
        ]),
        ...mapMutations([
            'setCRMTable',
            'clearCRMTable',
            'setCRMSearch',
            'CLEAR_CRM_MODAL',
        ]),
        closeAnalysisModalFunc() {
            this.openAnalysisModalBoolean = false
        },
        closeReportModalFunc() {
            this.openReportModalBoolean = false
        },
        openReportModalFunc() {
            this.openReportModalBoolean = true
        },
        openAnalysisModalFunc() {
            this.openAnalysisModalBoolean = true
        },
        openRangeDatePickerFunc() {
            this.datesRange = []
            this.openRangeDatePicker = !this.openRangeDatePicker
        },
        sortTableByEventDateRange() {
            const todayDate = moment(this.datesRange[0])
                .set({
                    hours: 1,
                    minutes: 0,
                    seconds: 0,
                    millisecond: 0,
                })
                .toISOString()
            const endDate = moment(this.datesRange[1])
                .set({
                    hours: 1,
                    minutes: 0,
                    seconds: 0,
                    millisecond: 0,
                })
                .add(1, 'days')
                .toISOString()
            this.findAllEventsBySelectedDate({
                startDate: todayDate,
                endDate: endDate,
            })
        },
        sortTableByEventDateToday() {
            const todayDate = moment()
                .set({
                    hours: 1,
                    minutes: 0,
                    seconds: 0,
                    millisecond: 0,
                })
                .toISOString()
            const endDate = moment()
                .set({
                    hours: 23,
                    minutes: 0,
                    seconds: 0,
                    millisecond: 0,
                })
                .toISOString()
            this.findAllEventsBySelectedDate({
                startDate: todayDate,
                endDate: endDate,
            })
        },
        add() {
            return this.checkPermissions('CREATE')
        },
        closeModal() {
            // this.clearCarsErrors()
            this.open = false
        },

        async addDataToCRM() {
            this.CLEAR_CRM_MODAL()
            this.open = true
        },
        setSearchFields(searchFields) {
            this.setCRMSearch(searchFields)
        },
        setSearch(search) {
            this.setCRMTable({ search, page: 1 })
            this.fetchCRMTable()
        },
        setPage(page) {
            this.setCRMTable({ page })
            this.fetchCRMTable()
        },
        setSort(sort) {
            this.setCRMTable({ sort })
            this.fetchCRMTable()
        },
        clearTable() {
            this.getSearch.text = ''
            this.clearCRMTable()
            this.fetchCRMTable()
        },
    },

    beforeRouteEnter(to, from, next) {
        if (from.matched[1] && from.matched[1].path !== '/offers/:id')
            store.commit('clearCRMTable')
        store.dispatch('fetchCRMTable').then(() => next())
    },
}
</script>
<style lang="scss" scoped>
.date-picker-range {
    position: absolute;
    bottom: 25px;
    left: 100px;
}
</style>
